<template>
	<div class="pat6">
		<div class="main">
			<!-- <b>Departure Plan</b> -->
			<h1>启航计划</h1>
			<h1>肿瘤科普作品征集项目</h1>
			<!-- <span>Open the door of nature and show the essence of humanity.</span> -->
			<el-button @click="$router.push({path:'/UserCenter'})">立即参与</el-button>
		</div>
	</div>
	</div>
</template>

<script>
	export default {
		name: 'pat6',
		components: {},
		data() {
			return {}
		},
		created() {},
		mounted() {},
		methods: {}
	}
</script>
<style lang="scss" scoped>
	.pat6 {
		padding: 100px 0;
		background: rgba(247, 248, 250, 1);

		.main {
			width: 1200px;
			margin: auto;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			b {
				color: rgba(203, 50, 44, 1);
				font-size: 16px;
			}

			h1 {
				font-size: 70px;
				font-weight: 600;
				line-height: 1.5;
				margin-bottom: 10px;
			}

			span {
				font-size: 14px;
				color: rgba(153, 153, 153, 1);
				margin-top: 16px;
			}

			.el-button {
				background-color: rgba(203, 50, 44, 1);
				color: #fff;
				width: 150px;
				height: 60px;
				margin-top: 40px;
				font-size: 16px;
				padding: 0;
			}
		}
	}
</style>