<template>
	<div class="pat2">
		<div class="main">
			<div class="left">
				<b>About us</b>
				<h1>项目介绍</h1>
				<div class="intr">
					<span>“</span>
					<div v-html="projectData.projectIntroduction"> </div>
				</div>
				<el-button>查看更多</el-button>
				<ul class="data">
					<li>
						<b>{{projectData.doctorNum}}</b>
						<i>注册人数</i>
					</li>
					<li>
						<b>{{projectData.documentNum}}</b>
						<i>投稿数量</i>
					</li>
					<li>
						<b>{{projectData.excellentNum}}</b>
						<i>入围作品</i>
					</li>
				</ul>
			</div>

			<el-image :src="projectData.projectImagePath" fit="cover"></el-image>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'pat2',
		props: {
			projectData: Object
		},
		data() {
			return {}
		},
		created() {},
		mounted() {},
		methods: {}
	}
</script>
<style lang="scss" scoped>
	.pat2 {
		padding: 150px 0;
		background: rgba(247, 248, 250, 1);

		.main {
			width: 1200px;
			margin: auto;
			display: flex;
			flex-direction: row;

			.left {
				flex: 1;

				b {
					color: rgba(203, 50, 44, 1);
					font-size: 16px;
				}

				h1 {
					font-size: 70px;
					font-weight: 600;
					line-height: 1.5;
				}

				.intr {
					display: flex;
					flex-direction: row;

					span {
						font-size: 50px;
						color: rgba(203, 50, 44, 1);
						line-height: 1;
						width: 50px;
						display: flex;
						align-items: flex-start;
						justify-content: flex-start;
						text-align: justify;
					}

					>div {
						flex: 1;
						font-size: 14px;
						color: rgba(102, 102, 102, 1);
						line-height: 2;
					}

				}

				.el-button {
					margin-left: 50px;
					background-color: #000;
					color: #fff;
					margin-top: 30px;
				}

				.data {
					margin-top: 50px;
					margin-left: 50px;
					display: flex;
					flex-direction: row;
					justify-content: space-between;

					li {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: flex-start;

						b {
							font-size: 40px;
							color: rgba(203, 50, 44, 1);
						}

						i {
							font-style: normal;
							color: rgba(153, 153, 153, 1);
						}
					}
				}
			}

			.el-image {
				width: 440px;
				min-height: 100%;
				margin-left: 120px;
			}
		}
	}
</style>