<template>
	<div class="pat1">
		<el-image class="background" :src="bannerPath" fit='cover'></el-image>
		<b class="mask"></b>
		<div class="main">
			<div class="cont">
				<p style="font-size: 150px;">启航计划</p>
				<p>肿瘤科普作品征集项目</p>
				<b>项目周期：2023年3月-2023年12月</b>
				<b>主办单位：北京生命绿洲公益服务中心</b>
				<b>鸣谢支持：<img :src="require('@/assets/imgs/QL_logo.png')" alt=""></b>
			</div>

			<!-- <div class="el-button"></div> -->
			<el-button type="primary" @click="$router.push({path:'Intr'})">了解更多</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'pat1',
		props: {
			bannerPath: String
		},
		data() {
			return {}
		},
		created() {},
		mounted() {},
		methods: {}
	}
</script>
<style lang="scss" scoped>
	.pat1 {
		min-height: 900px;
		position: relative;

		.webHander {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			z-index: 1;
		}

		.background {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 0;
		}

		.mask {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			z-index: 0;
			background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(252, 253, 255, 1) 100%)
		}

		.main {
			position: absolute;
			width: 1200px;
			top: 0;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.cont {
				width: 1200px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				p {
					font-size: 60px;
					font-weight: 600;
					line-height: 1.5;
				}

				b {
					font-size: 16px;
					font-weight: normal;
					margin-top: 12px;
					color: #000;
					opacity: .6;
					line-height: 1.5;

					img {
						height: 36px;
					}
				}
			}

			.el-button {
				font-size: 16px;
				// background-color: #000;
				// color: #fff;
				width: 180px;
				height: 66px;
				line-height: 65px;
				border-radius: 33px;
				padding: 0;
				margin-top: 70px;
			}
		}
	}
</style>